import "./home.scoped.scss";

import Ontwerp from "assets/ontwerp.jpg";
import DemoPuzzelMenu from "assets/demo-puzzel-menu.png";
import DemoThreejsKubussen from "assets/demo-threejs-kubussen.png";
import AdviesMultiplayerEindconslusie from "assets/advies-multiplayer-eindconclusie.jpg";
import Pipeline from "assets/Pipeline.png";

export default function Home() {

  return (
    <div className="container">
      <div className="body" >
        <section id="inleiding">
          <div className="section-header">
            <h2>Inleiding</h2>
          </div>
          <div className="section-body">
            <h3>Wie ben ik?</h3>
            <p>
              Ik ben Casper Torén, 21 jaar oud en student aan Fontys Hogeschool Eindhoven ICT.
              Ik heb Software als richting gekozen en heb deze stage ook in deze richting gedaan. <br /><br />
              Ik heb stage gelopen bij Embedded Fitness is Helmond. 
              Tijdens mijn stage kreeg ik de opdracht om een game framework te maken voor het SmartMove project van Embedded Fitness. 
            </p>

            <h3>Stagebedrijf en opdracht</h3>
            <p>
            Het stagebedrijf waar ik stage heb gelopen is Embedded Fitness. Embedded fitness is een middelgroot bedrijf dat verschillende exergames (Video games gecombineerd met lichaamsbeweging) ontwikkelt. Het is gevestigd in Helmond 
            </p>
          </div>
        </section>

        <section id="analyse" style={{backgroundColor: "#fdedd2"}}>
          <div className="section-header">
            <h2>Analyse</h2>
          </div>
          <div className="section-body">
            <p>
              De eerste paar weken van mijn stage is besteed aan de analyse van het project.
              <br /><br />
              Ik ben begonnen met een aantal interviews met mijn bedrijfsbegeleider over de stage opdracht,
              specifieker wat het SmartMove project is. Hieruit bleek dat de SmartMove een exergame is die bedoeld is om mensen naar buiten te krijgen om te bewegen.
              Dit wordt gedaan door langs de weg meerdere paaltjes neer te zetten waarmee de SmartMove app kan communiceren.
              Hiervoor kunnen meerdere spellen worden ontwikkelt zoals een snelheid spel waarin de spelen zo snel mogelijk langs de paaltjes af moet rennen.
              <br /><br />
              Om deze spellen te maken is er een javascript game framework gemaakt.
              Uit de gesprekken bleek dat het gemaakt framework meerdere limitatie/problemen had en dat deze zouden moeten worden opgelost om de ontwikkelen van de spellen te kunnen versnellen.
              Hieruit volgde de probleemstelling dat het huidige framework limitaties/problemen heeft waardoor het de ontwikkeling van de spellen vertraagd.
              De opdracht was daarom ook om een framework te maken die deze limitatie/problemen niet heeft.
              <br /><br />
              Uiteindelijk heb ik gekozen om dit project aan de hand van Scrum te doen omdat dit een flexibele manier van werken is en de requirements nog niet duidelijk waren  en er nog een aantal onzekerheden waren afhankelijk van het onderzoek.
              Voor de hele analyse kijk Project Plan.
            </p>
          </div>
        </section>

        <section id="onderzoek">
          <div className="section-header">
            <h2>Onderzoek</h2>
          </div>
          <div className="section-body">
            <p>
              In het onderzoek met de hoofdvraag: “Hoe kan het SmartMove game framework worden aangepast of opnieuw gemaakt zodat de problemen en limitaties zijn opgelost?” wilde ik erachter komen wat de problemen/limitaties van het oude framework waren, welke technieken het best paste bij het framework, of het oude framework gerefactored kon worden en of het nieuwe framework de limitaties/problemen oplost. 
              <br /><br />
              Om erachter te komen welke problemen/limitaties het oude had heb ik eerste onderzoek gedaan naar de requirements <a href="/onderzoeksrapport">(3.1 onderzoeksrapport)</a>. De requirements konden worden verdeeld onder drie stakeholders: de spelontwikkelaar, de klant en de gebruiker. Hierna heb ik gekeken of het oude framework aan deze requirements voldeed om achter de limitaties en problemen te komen. Voor deze limitaties heb ik hierna oplossingen gevonden en deze getest door middel van prototypes <a href="/onderzoeksrapport">(dit is te vinden onder 3.2 onderzoeksrapport)</a>.
              <br />
              <strong>1.	Het huidige Framework is moeilijk te onderhouden (Requirement 2 en 3 spelontwikkelaars)</strong><br />
              Dit is op te lossen door SOLID te implementeren. SOLID brengt meerdere dingen met zich mee, onder anderen dat het ervoor zorgt dat een component makkelijk te vervangen is door een andere en dat het toevoegen van componenten makkelijk is.
              <br /><br />
              <strong>2.	Simpele spellen moeten snel kunnen worden gemaakt (Requirement 4 spelontwikkelaars)</strong><br />
              Dit is op te lossen door een toolbox te maken. In deze toolbox zullen voorgemaakte componenten zitten die bij het maken van de spellen gebruikt kunnen worden. Hierdoor hoeft bij elk spel niet alles opnieuw te worden gemaakt. Mochten er voor bepaalde spellen componenten ontbreken aan de toolbox kunnen deze worden toegevoegd om dit ook bij toekomstige spellen te gebruiken, zo wordt de toolbox steeds verder uitgebreid.
              <br /><br />
              <strong>3.	Framework is niet data driven (Requirement 5 spelontwikkelaars)</strong><br />
              Hiervoor maak ik een DataHandler die de data doorgeeft aan het spel tijen de build. Hierdoor kunnen er verschillende versies van één type spel worden gemaakt aan de hand van de data. Zo kan je van een puzzelspel (het type spel) verschillende versies maken, zoals andere puzzels etc. 
              Het hele framework data driven maken is bijna niet mogelijk omdat er altijd nog extra functies voor bepaalde type spellen moeten worden toegevoegd waarvoor code zal moeten worden geschreven. Hiervoor kan ik wel een basis neerleggen zodat alle spellen dezelfde structuur gebruiken en niet elke spel vanaf het begin af een opnieuw hoeft te worden gemaakt.
              <br /><br />
              <strong>4.	Complexe spellen kunnen moeilijk met het huidige framework worden gemaakt(Requirement 6 spelontwikkelaars)</strong><br />
              Hiervoor zal een bestaand javascript game framework in het framework worden geïmplementeerd. Hierdoor is de complexe logica al gemaakt en kunnen complexe spellen sneller worden gemaakt. Het nieuwe framework zal als een wrapper fungeren om het javascript game framework heen die verantwoordelijk is voor de logica van Embedded Fitness specifiek.
              <br /><br />
              <strong>5.	Spel maken voor beide telefoon en SmartMove Screen (Requirement 7 spelontwikkelaars)</strong><br />
              Tijdens het genereren van een spel via JSON kan er gespecificeerd worden of het een SmartMove screen spel is of een telefoon spel. Op het SmartMove screen zullen er andere functies zijn dan op een telefoon, daarom kan het niet zo zijn dat dezelfde versie van een spel op allebei kan worden gespeeld.
              <br /><br />
              <strong>6.	De spellen duren te lang om in te laden naarmate er meerdere spellen komen (Requirement 1 gebruikers)</strong><br />
              Zoals eerder beschreven heeft bij het huidige framework elk spel de info van alle spellen. Om dit tegen te gaan maak ik gebruik van code splitting. Tijdens het builden van een game wordt de naam of id van het des betreffende spel doorgegeven waardoor het framework alleen de info (code, plaatjes etc.) van het gespecificeerde spel in de build stopt. Dit zorgt ervoor dat elk spel alleen de informatie van zichzelf bevat en niet meer van andere spellen.
              
              <br /><br /><br />
              Nadat de limitatie/problemen en de oplossingen hiervoor in kaart waren gebracht heb ik dit vergeleken met het oude framework om in te schatten of het mogelijk was om deze te refactoren <a href="/onderzoeksrapport">(dit is te vinden onder 3.3 onderzoeksrapport)</a>. Hieruit bleek dat alles van het oude framework gerefactored zou moeten worden en het beter was om een nieuw framework te maken. 
              <br /><br />
              Nu dat het duidelijk was dat er een nieuw framework zou moeten worden gemaakt en aan welke requirements deze moest voldoen ontstond de vraag welk front-end framework hier het best voor gebruikt kon worden <a href="/onderzoeksrapport">(dit is te vinden onder 3.4 onderzoeksrapport)</a>.  Op basis van een aantal requirements bleek dat Preact hiervoor de beste keus was.
              <br /><br />
              Als laatst moest er nog onderzocht worden naar welk game framework geïmplementeerd zou worden voor de complexe spellen aangezien dit de oplossing was voor een probleem <a href="/onderzoeksrapport">(dit is te vinden onder 3.5 onderzoeksrapport)</a>.  Dit was net als het front-end framework gebaseerd op de requirements en bleek dat ThreeJS hier de beste keus voor is.
              <br /><br /><br />
              Halverwege het onderzoek moest er een aantal dingen worden aangepast omdat ik een stapje terug heb gedaan in het project om opnieuw naar de scope ervan te kijken (hierover meer onder bijzonderheden). Door de flexibiliteit van Scrum was dit geen probleem en heb ik de veranderingen snel kunnen meenemen in het onderzoek.
              <br /><br /><br />
              Aan het eind van het onderzoek ben ik teruggegaan naar de hoofdvraag en bleek dat er een nieuw framework zou worden gemaakt. Met de gevonden oplossingen in 3.2 zijn de limitaties opgelost. Het framework zou in Preact worden gemaakt en ThreeJS zou worden gebruikt als het game framework voor de complexere spellen. Het game framework voldeed uiteindelijk alleen niet aan de Multiplayer requirement, dit was geen probleem aangezien deze een lage prioriteit had. Hier is een Advies voor geschreven.

            </p>
          </div>
        </section>

        <section id="ontwerp" style={{backgroundColor: "#fdedd2"}}>
          <div className="section-header">
            <h2>Ontwerp</h2>
          </div>
          <div className="section-body">
            <p>
              Nadat de globale onderdelen van het framework tijdens het onderzoek bekend werden ben ik begonnen aan het ontwerpen van het framework. Dit heb ik doormiddel van een c4 model gedaan omdat hiermee op verschillenden technische niveaus gecommuniceerd kan worden.
              <br /><br />
              Hieronder staat niveau twee van het c4 model beschreven omdat deze het framework het best samenvat. Kijk “Ontwerpdocument” voor volledige c4 model.
              <br /><br />
              Het spel (Framework) communiceert met de Smart Move app om data te krijgen van de backend. Het framework zelf is opgedeeld in drie onderdelen, De Common (Wrapper), ThreeJS en de Toolbox.
              <br /><br />
              <strong>De Common</strong> is verantwoordelijk voor de logica die alle spellen nodig hebben zoals de communicatie met de SmartMove app. 
              <br />
              <strong>De ThreeJS</strong> is het gekozen Game Framework, hiermee worden de complexe spellen gemaakt.
              <br />
              <strong>De Toolbox</strong> zijn de voorgemaakt componenten waarmee de simpele spellen worden gemaakt. Ook geeft de Toolbox een structuur aan deze componenten.
              <br /> <br /> <br /> <br />
              <img src={Ontwerp} alt="ontwerp" className="design-image"/>
            </p>
          </div>
        </section>

        <section id="uitwerking">
          <div className="section-header">
            <h2>Uitwerking</h2>
          </div>
          <div className="section-body">
            <p>
              Tijdens het ontwerpen en het onderzoeken ben ik begonnen aan het framework zelf. Omdat ik scrum gebruikte kon ik afwisselen tussen onderzoeken – ontwerpen – realiseren. Uiteindelijk heb ik alle must en should haves geïmplementeerd. Hiernaast heb ik twee demo spellen gemaakt die de werking van de Toolbox en ThreeJS integratie weergeven.
              <br /><br />
              <h3>Toolbox demo spel</h3>
              Voor het Toolbox demo spel is er een puzzel spel gemaakt. In het spel worden meerdere componenten van de Toolbox gebruikt en het Common onderdeel gebruikt.
              <br />
              Hieronder is een menu en header component te zien. Dit is het menu van het puzzel spel.

              <img src={DemoPuzzelMenu} alt="demo-puzzel-menu" /><br /><br />
              
              <br/><br/><br/><br/><br/>
              <h3>ThreeJS demo spel</h3>
              Voor het demo spel is reactie spel gemaakt met 3D roterende kubussen. Hierdoor wordt de 3D functionaliteit van ThreeJS weergegeven.<br /> <br />
              Hieronder zijn de kubussen te zien, ook is er een header component te zien van de Toolbox. Toolbox componenten kunnen ook bij ThreeJS spellen worden gebruikt indien nodig. 
              <br/>
              <img src={DemoThreejsKubussen} alt="three-js-demo-kubussen" /><br /><br />

              Om het framework te testen heb ik onder het realiseren van het framework een tests geschreven die het common onderdeel van het framework testen. Testen had bij Embedded Fitness niet een hoge prioriteit, daarom heb ik alleen de belangrijke onderdelen van het framework getest(de Common). Deze tests worden elke keer gedraaid in een CI/CD pipeline wanneer er naar de Bit Bucket repository wordt gepushed.
              <br /><br />
              Hieronder is een commit te zien met de dingen die de pipeline uitvoert.
              <img src={Pipeline} alt="Pipeline" />
            </p>
          </div>
        </section>

        <section id="adviezen" style={{backgroundColor: "#fdedd2"}}>
          <div className="section-header">
            <h2>Adviezen</h2>
          </div>
          <div className="section-body">
            <p>
              <h3>Multiplayer advies</h3>
              Aangezien het game framework niet voldoet aan de multiplayer requirement is er een onderzoek gedaan naar hoe de multiplayer functionaliteit/connectie in de SmartMove kan worden geïmplementeerd. Dit onderzoek is opgedeeld in drie deelvragen:
              <br /><br />
              1.	Is de SmartMove app of het framework verantwoordelijk voor de multiplayer connectie met de backend?<br />
              2.	Welke techniek kan het best gebruikt worden om multiplayer te realiseren?<br />
              3.	Hoe kan de gevonden techniek in flutter worden geïmplementeerd?<br />
              <br />
              Samen met dit onderzoek adviseer ik om de multiplayer connectie met de backend door de SmartMove app te laten doen. De techniek waarmee de connectie het best kan worden gemaakt is Websockets aangezien dit voor minimale vertraging zorgt en een lage druk op de servers legt. Websockets kan met Socket.io worden geïmplementeerd in flutter. Door Socket.io te gebruiken hoeft er geen rekening meer te worden gehouden met het herstellen van verbroken verbindingen of een fallback wanneer Websockets niet mogelijk zijn.
              De implementatie ziet er zo uit: <br /><br />

              <img src={AdviesMultiplayerEindconslusie} alt="advies-multiplayer-eindconclusie" /><br /><br />

              <h3>Toekomst advies</h3>
              Het framework is op een manier ontwikkelt, door bijvoorbeeld bepaalde SOLID principes, zodat het makkelijk uitbereidbaar is. In de nabije toekomst adviseer ik om simpele spellen te beginnen te maken en zo de Toolbox uit te bereiden. Hierdoor wordt het framework steeds uitgebreider en zal de ontwikkelingssnelheid van spellen toenemen.
              <br /><br />
              Ook adviseer ik om te kijken naar het maken van een NPM package van het framework. Hiervoor was binnen de stageperiode geen tijd maar het zou wel meerdere voordelen met zich meebrengen. Zo hoeft niet elk spel meer in dezelfde repository te staan maar kan het framework geïmporteerd worden in lossen projecten van elk spel. Ook kan het code splitting stukje van het framework weg aangezien dit dan al automatisch opgelost is doordat de spellen niet in dezelfde repository zitten.
            </p>
          </div>
        </section>

        <section id="reflectie">
          <div className="section-header">
            <h2>Reflectie</h2>
          </div>
          <div className="section-body">
            <h3>Communicatie</h3>
            <p>
              De communicatie met de bedrijfsbegeleider ging goed. Elke twee weken begon een nieuwe sprint en werd er een spring review gedaan en spring planning gedaan. Aan de hand hiervan werd het scrumboard aangepast. Tussen deze sprint reviews waren er genoeg momenten om met de bedrijfsbegeleider te overleggen, dit werd op een informele manier gedaan. <br /><br /> 
              De communicatie met de stagebegeleider verliep ook goed. Elke week werd er een feedpulse ingevuld. Wanneer er een onderdeel af was en hier feedback over nodig was werd dit in canvas ingeleverd. In het geval dat er onduidelijkheden over de feedback van de stagebegeleider was werd er een gesprek ingepland om het hier over te hebben.
            </p>

            <h3>Persoonlijke leerdoelen</h3>
            <p>
              <strong>Code Reusability</strong> <br /> 
              Op het gebied van code Reusability ben ik sterk vooruit gegaan. Door een van de requirements heb ik gebruik gemaakt van de SOLID principes. Ik had eerder me deze principes gewerkt maar nog nooit in een front-end applicatie. In een Preact applicatie ziet dit er toch iets anders uit dan in bijvoorbeeld een Spring Boot applicatie omdat Preact component based is waardoor de principes op een andere manier worden geïmplementeerd.<br />
              <strong>DOT framework</strong><br /> 
              Het onderzoek is aan de hand van het DOT framework gedaan. Voor mijn stage had ik één keer met het DOT framework gewerkt, hierdoor had ik voor mijn gevoel nog geen goed idee van welke methodes ik het best kon gebruiken voor bepaalde deelvragen. <br /> 
              Na deze stage ben ik hier sterk op vooruit gegaan, ik heb een beter gevoel voor welke methodes bij welke deelvragen passen en hoe ik triangulatie toepas om tot een goede conclusie te komen. <br /> 
              Ook ben ik erachter gekomen dat door het DOT framework ik aan het begin van het onderzoek meer tijd ben aan het opstellen van de structuur maar dat ik de tijd terugwin gaandeweg het onderzoek omdat de structuur al vast staat.<br /> 
              <strong>Teamwork</strong><br />
              Dit is in de stage iets minder teruggekomen dan gehoopt. Aangezien ik aan een onderdeel van het SmartMove project werkte waar niemand anders aan werkte heb ik in dat opzicht alleen gewerkt. Wel heb ik in de onderzoeken andere developers erin proberen te betrekken om het zo goed af te stemmen met de rest van het SmartMove project.
              Als laatst heb ik ook rekening gehouden met de toekomstige developers. De bedoeling van een game framework is dat mensen het gaan gebruiken om spellen te maken. Hiervoor moeten de developers begrijpen hoe het werkt en ligt bij mij de taak om het zo goed mogelijk uit te leggen/documenteren. Hiervoor zijn er ontwerp modellen en andere documenten om de werking van het framework duidelijk te maken. Dit is naar mijn mening ook een vorm van samenwerken die minstens net zo belangrijk is.
            </p>

            <h3>Bijzonderheden</h3>
            <p>
              Halverwege mijn stage had ik problemen met de scope van het project. Om opnieuw te kijken naar de scope heb ik een stapje achteruit gedaan en zo een nieuwe blik op het project te krijgen. Door met de stagebegeleider gesprekken te houden is het gelukt om een nieuwe richting te geven aan het project.
              <br /> <br /> 
              Hiervoor heb ik eerst interviews gehouden met de bedrijfsbegeleider over de scope van het project en onduidelijkheden hierover.
              Na deze gesprekken bleek dat het framework in twee delen kon worden opgesplitst, het onderdeel voor de simpele spellen en het onderdeel voor de complexe spellen. Er bleek ook dat voor de complexe spellen een bestaand game framework kon worden gebruikt aangezien hier een eigen framework voor maken niet haalbaar was. Voor de simpele spellen waren voorgemaakte componenten nodig die gebruikt konden worden voor tijdens het ontwikkelen van deze spellen. Hierdoor viel de complexe logica van spellen niet binnen de scope van het project en was het alleen nodig om een aantal voorgemaakte componenten te maken en hier een structuur aan te geven. Voor de algemene logica die elk spel nodig heeft, ongeacht complex of simpel, zou er een common gedeelte gemaakt moeten worden.
              <br /><br />
              De les die ik hieruit heb getrokken is dat ik tijdens de analyse fase de volgende keren langer bij de randvoorwaarden (scope) van het project stil sta. In de randvoorwaarden is meegenomen dat er een game framework moet worden gemaakt, dit is een vrij breed begrip en omdat hier niet dieper op in is gegaan zijn er halverwege het project onduidelijkheden hierover gekomen. Gelukkig kon het deze keer worden opgelost door een stapje terug te doen wat ook mogelijk is door de flexibiliteit van Scrum.
            </p>
          </div>
        </section>

        <footer>
          <p>All rights reserved ©{new Date().getFullYear()} Casper Torén</p>
        </footer>
      </div>
    </div>
  );
}