import { useState } from 'react';
import "./advies.scoped.scss";
import { Document, Page, pdfjs } from 'react-pdf';

import OnderzoeksDocument from "./multiplayer onderzoeksrapport.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function Researh() {
  const [pages, setPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setPages(numPages);
  }


  return (
    <div className="container">
      <div className="file-heading">
        <h1>Onderzoeksrapport</h1>
        <a href={OnderzoeksDocument}>Click voor pdf weergave en om te downloaden</a>
      </div>
      <div className="file-container">
        <Document
          file={OnderzoeksDocument}
          onLoadSuccess={onDocumentLoadSuccess}
          noData={""}
          loading={""} 
          error={""}
        >
          {Array.from(Array(pages).keys()).map(pageNumber => {
            return (
              <Page className="page" pageNumber={pageNumber} width={1000} error={""}/>
            );
          })}
        </Document>
      </div>
    </div>
  );
}