import { useEffect, useState, createRef } from "react";
import "./header.scoped.scss";

interface Props {
  headerColorSwitchOffset: number;
}

export default function Header(props: Props) {
  const [headerColorSwitch, setHeaderColorSwitch] = useState<boolean>(false);
  const [dropdownState, setDropdownState] = useState<boolean>(false);
  let headerRef = createRef<HTMLHeadingElement>();
  let dropdownRef = createRef<HTMLDivElement>();

  document.addEventListener('scroll', function(e) {
    if((window.scrollY/window.innerHeight)*100 > props.headerColorSwitchOffset) {
      setHeaderColorSwitch(true);
    } else {
      setHeaderColorSwitch(false);
    }
  });

  useEffect(() => {
    let header = headerRef.current;

    if (header !== null) {
      if (headerColorSwitch) {
        header.classList.add("header-color-switched");
      } else {
        header.classList.remove("header-color-switched");
      }
    }
  });

  useEffect(() => {
    let dropdown = dropdownRef.current;
    if (dropdown !== null) {
      if (!dropdownState) {
        dropdown.style.removeProperty("max-height");
      } else {
        dropdown.style.maxHeight = dropdown.scrollHeight + "px";
      }
    }
  }, [dropdownState]);

  return (
    <header ref={headerRef}>
      <ul>
        <li>
          <a href="/">Leeswijzer</a>
        </li>
        <li>
          <a href="/analysedocument">Analyse</a>
        </li>
        <li>
          <a href="/onderzoeksrapport">Onderzoek</a>
        </li>
        <li>
          <a href="/ontwerpdocument">Ontwerp</a>
        </li>
        <li>
          <a href="/multiplayer_onderzoek">Muliplayer onderzoek</a>
        </li>
      </ul>
    </header>
  );
}