import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scoped.scss';
import "./global.scss";

import Home from "routes/home/home";
import Analyse from "routes/analyse/analyse";
import Research from "routes/research/research";
import Ontwerp from "routes/ontwerp/ontwerp";
import Advies from "routes/advies/advies";

import Header from "components/header/header";

function App() {

  return (
    <div className="app">
      <Header headerColorSwitchOffset={30}/>

      <div className="heading">
        <div className="banner-container">
          <h1>Embedded Fitness</h1>
          <h2>Casper Torén</h2>
          <h3></h3>
        </div>
      </div>
      <div className="body">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/analysedocument" element={<Analyse />} />
            <Route path="/onderzoeksrapport" element={<Research />} />
            <Route path="/ontwerpdocument" element={<Ontwerp />} />
            <Route path="/multiplayer_onderzoek" element={<Advies />} />
          </Routes>
        </Router>
      </div>
    </div>

  );
}

export default App;
